export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  HTML: any;
};

export type AccordionItem = {
  __typename?: 'AccordionItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<AccordionItemContent>>>;
  title: Scalars['String'];
};

export type AccordionItemContent = BlockHtmlList | BlockHtmlParagraph | BlockImage;

export type Address = {
  __typename?: 'Address';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  address1: Scalars['String'];
  address2: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  zipcode: Scalars['String'];
};

export type Animal = {
  __typename?: 'Animal';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<Animal>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  name: Scalars['String'];
  translations: Array<Animal>;
};

export type BackgroundStoryContent = BlockHtmlList | BlockHtmlParagraph;

export type BackgroundStoryMedia = MediaImage | MediaVideo;

export type Block = BlockAccordion | BlockBackgroundStory | BlockBookingSummary | BlockBookingSurvey | BlockCBox | BlockCollageStory | BlockContactBox | BlockContentHub | BlockConvertFlow | BlockDonationWidget | BlockDynamicTeaserList | BlockFactSheet | BlockFileList | BlockForm | BlockHtmlList | BlockHtmlParagraph | BlockHtmlTable | BlockIframe | BlockImage | BlockImageCarousel | BlockImageStory | BlockImpactNumbers | BlockLocationSearch | BlockLoginRegister | BlockMap | BlockMyProfile | BlockMyProfileEdit | BlockPetition | BlockQuote | BlockSiteSearch | BlockTeaserList | BlockThumbnailGallery;

/**
 * A accordion block containing other content blocks inside (paragraphs, images,
 * lists).
 */
export type BlockAccordion = {
  __typename?: 'BlockAccordion';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  contentItems: Maybe<Array<Maybe<AccordionItem>>>;
};

/** A block containing markup with an image or video as a background. */
export type BlockBackgroundStory = {
  __typename?: 'BlockBackgroundStory';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  bgStoryBackground: Maybe<BackgroundStoryMedia>;
  bgStoryContent: Maybe<Array<Maybe<BackgroundStoryContent>>>;
  displaySource: Maybe<Scalars['Boolean']>;
  textPosition: Maybe<BlockBackgroundStoryTextPosition>;
};

export enum BlockBackgroundStoryTextPosition {
  Left = 'left',
  Right = 'right'
}

/** A block containing the summary of a booking (used on the booking thank you page). */
export type BlockBookingSummary = {
  __typename?: 'BlockBookingSummary';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/** A block containing the booking completed survey form. */
export type BlockBookingSurvey = {
  __typename?: 'BlockBookingSurvey';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/** A featured box containing heading, subheading and assorted blocks */
export type BlockCBox = {
  __typename?: 'BlockCBox';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  content: Maybe<Array<Maybe<CBoxContent>>>;
  heading: Scalars['String'];
  subheading: Maybe<Scalars['String']>;
};

/**
 * A block containing a collage of 2 images and a text positioned at the top or the
 * bottom of them.
 */
export type BlockCollageStory = {
  __typename?: 'BlockCollageStory';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  collageBigImage: Maybe<CollageImage>;
  collageContent: Maybe<Array<Maybe<CollageStoryContent>>>;
  collageSmallImage: Maybe<CollageImage>;
  collageTextPosition: Maybe<BlockCollageStoryTextPosition>;
};

export enum BlockCollageStoryTextPosition {
  BottomLeft = 'bottom_left',
  BottomRight = 'bottom_right',
  TopLeft = 'top_left',
  TopRight = 'top_right'
}

export type BlockContact = {
  __typename?: 'BlockContact';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  contact: Maybe<MediaContact>;
};

/** A block containing a list of contact media items. */
export type BlockContactBox = {
  __typename?: 'BlockContactBox';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  contacts: Maybe<Array<Maybe<BlockContact>>>;
};

export type BlockContentHub = {
  __typename?: 'BlockContentHub';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** Countries to filter the content hub by */
  countries: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Whether or not to display the form above the teasers and pagination */
  displayFilters: Maybe<Scalars['Boolean']>;
  /** Formats to filter the content hub by */
  formats: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Hide individual filters */
  hideFilters: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The number of items to display per page on the listing */
  itemsPerPage: Maybe<Scalars['String']>;
  /** Topics to filter the content hub by */
  topics: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A block to embed a ConvertFlow form */
export type BlockConvertFlow = {
  __typename?: 'BlockConvertFlow';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  convertFlowId: Scalars['String'];
};

/**
 * Displays the large in-page donation widget. The donation purpose referenced by
 * the page is used to retrieve all information.
 */
export type BlockDonationWidget = {
  __typename?: 'BlockDonationWidget';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /**
   * Dummy field because GraphQL does not allow empty types.
   * At this point the donation widget block is a pure placeholder, but that might
   * change.
   */
  dummy: Maybe<Scalars['String']>;
};

/**
 * Gutenberg block that embeds a list of items into a page that can be displayed
 * in different ways.
 */
export type BlockDynamicTeaserList = {
  __typename?: 'BlockDynamicTeaserList';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** The list display variants. */
  display: Maybe<TeaserListDisplay>;
  injectedHeading: Maybe<Scalars['String']>;
  /** The media item storing the listing configuration. */
  listing: Maybe<MediaTeaserListing>;
  /** Toggle to show teaser item descriptions or not. */
  showDescriptions: Scalars['Boolean'];
};

export type BlockFactSheet = {
  __typename?: 'BlockFactSheet';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  factSheetItems: Maybe<Array<Maybe<BlockFactSheetItem>>>;
  injectedHeading: Maybe<Scalars['String']>;
};

export type BlockFactSheetItem = {
  __typename?: 'BlockFactSheetItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  factSheetContent: Maybe<Array<Maybe<FactSheetContent>>>;
  factSheetHeading: Maybe<Scalars['String']>;
};

export type BlockFileList = {
  __typename?: 'BlockFileList';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  fileListAnchor: Maybe<Scalars['String']>;
  fileListContent: Maybe<Scalars['String']>;
  fileListHeading: Maybe<Scalars['String']>;
  files: Maybe<Array<Maybe<BlockFileListItem>>>;
};

export type BlockFileListItem = {
  __typename?: 'BlockFileListItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  file: Maybe<FileListMedia>;
};

/** A block to embed Drupal webforms via an iframe */
export type BlockForm = {
  __typename?: 'BlockForm';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  anchor: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** A list block that can contain unordered and ordered lists */
export type BlockHtmlList = {
  __typename?: 'BlockHtmlList';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  html: Scalars['HTML'];
};

/** A simple block containing renderable html content for paragraphs */
export type BlockHtmlParagraph = {
  __typename?: 'BlockHtmlParagraph';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  html: Scalars['HTML'];
};

export type BlockHtmlTable = {
  __typename?: 'BlockHtmlTable';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  html: Scalars['HTML'];
};

/** A block to embed a URL in an iframe */
export type BlockIframe = {
  __typename?: 'BlockIframe';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  media: Maybe<EmbedMedia>;
};

export enum BlockIframeEmbedType {
  Default = 'default',
  Issuu = 'issuu',
  Soundcloud = 'soundcloud',
  Spotify = 'spotify',
  Unknown = 'unknown',
  Vimeo = 'vimeo',
  Youtube = 'youtube'
}

/** Solitary image displayed on the page, sourced from a Drupal media entity. */
export type BlockImage = {
  __typename?: 'BlockImage';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  displayDescription: Maybe<Scalars['Boolean']>;
  /** Has to display a dummy image when the actual image is missing for any reason. */
  image: Maybe<MediaImage>;
};

/** An image carousel is basically just a simple collection of images. */
export type BlockImageCarousel = {
  __typename?: 'BlockImageCarousel';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  images: Maybe<Array<Maybe<BlockImage>>>;
  layout: Maybe<Scalars['String']>;
};

/** A block containing markup with an image on the side. */
export type BlockImageStory = {
  __typename?: 'BlockImageStory';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  displaySource: Maybe<Scalars['Boolean']>;
  imageStoryContent: Maybe<Array<ImageStoryContent>>;
  imageStoryImage: Maybe<MediaImage>;
  imageStoryTextPosition: Maybe<BlockImageStoryTextPosition>;
};

export enum BlockImageStoryTextPosition {
  Left = 'left',
  Right = 'right'
}

export type BlockImpactNumbers = {
  __typename?: 'BlockImpactNumbers';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  injectedHeading: Maybe<Scalars['String']>;
  items: Maybe<Array<Maybe<ImpactElement>>>;
};

/** A block which will show the location search widget in the FE. */
export type BlockLocationSearch = {
  __typename?: 'BlockLocationSearch';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  viewMode: Scalars['String'];
};

/** A block containing the login/register form. */
export type BlockLoginRegister = {
  __typename?: 'BlockLoginRegister';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/** A map block is a collection of Location media items. */
export type BlockMap = {
  __typename?: 'BlockMap';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  injectedHeading: Maybe<Scalars['String']>;
  locations: Maybe<Array<Maybe<BlockMediaLocation>>>;
  mapSize: Maybe<MapSize>;
  zoomLevel: Maybe<Scalars['Int']>;
};

export type BlockMediaLocation = {
  __typename?: 'BlockMediaLocation';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  location: Maybe<MediaLocation>;
};

/** A block containing the profile view */
export type BlockMyProfile = {
  __typename?: 'BlockMyProfile';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/** A block containing the profile edit form */
export type BlockMyProfileEdit = {
  __typename?: 'BlockMyProfileEdit';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/** A block containing the results of a petition form. */
export type BlockPetition = {
  __typename?: 'BlockPetition';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  description: Maybe<Scalars['String']>;
  webformId: Scalars['String'];
};

/** A quote block, allowing for the addition of a supporting image, name and role. */
export type BlockQuote = {
  __typename?: 'BlockQuote';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  media: Maybe<MediaImage>;
  name: Maybe<Scalars['String']>;
  quote: Scalars['String'];
  role: Maybe<Scalars['String']>;
};

/** A block containing the site search widget. */
export type BlockSiteSearch = {
  __typename?: 'BlockSiteSearch';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** At the moment, we don't have any fields on this type, so just add a dummy one. */
  dummy: Maybe<Scalars['String']>;
};

/**
 * A list of teasers, source from either a dynamic query (e.g. "all news for topic
 * x") or a handpicked list of items (e.g. Drupal relation, entity queue ...)
 *
 * If a Teaser List appears directly after a heading, they are visually combined
 * and the teaser controls appear on the same level as the heading.
 */
export type BlockTeaserList = {
  __typename?: 'BlockTeaserList';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  injectedHeading: Maybe<Scalars['String']>;
  /** What layout the teaser list should take */
  layout: Maybe<Scalars['String']>;
  /** Don't display teaser item descriptions in this list. */
  showDescriptions: Scalars['Boolean'];
  /** The list of teaser items. */
  teasers: Maybe<Array<Maybe<Teaser>>>;
};

/** An thumbnail gallery is basically just a simple collection of images. */
export type BlockThumbnailGallery = {
  __typename?: 'BlockThumbnailGallery';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  thumbnails: Maybe<Array<Maybe<BlockImage>>>;
};

export type Booking = {
  __typename?: 'Booking';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  id: Scalars['String'];
  missionItems: Array<MissionItem>;
  status: BookingStatus;
};

export type BookingCreateResponse = {
  __typename?: 'BookingCreateResponse';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  createdBookings: Maybe<Array<Booking>>;
  invalidBookings: Maybe<Array<InvalidBooking>>;
};

export type BookingInput = {
  missionItems: Array<Scalars['String']>;
  userId: InputMaybe<Scalars['String']>;
};

export enum BookingStatus {
  Active = 'active',
  Inactive = 'inactive'
}

/** Users can select multiple visual items to throw into a donation box */
export type BoxDonation = {
  __typename?: 'BoxDonation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<BoxDonation>;
  callToAction: Scalars['String'];
  closedBoxImage: Maybe<MediaImage>;
  customOption: Maybe<Scalars['Boolean']>;
  donationPurpose: Maybe<Donation>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  /**
   * The items available in this DIY donation.
   * Should be maintained within the node itself. Maybe a field collection,
   */
  items: Array<BoxDonationOption>;
  labelBoxContentTitle: Maybe<Scalars['String']>;
  labelBoxContentValue: Maybe<Scalars['String']>;
  labelCloseBoxButton: Maybe<Scalars['String']>;
  labelCustomAmount: Maybe<Scalars['String']>;
  labelCustomAmountButton: Maybe<Scalars['String']>;
  labelCustomAmountDescription: Maybe<Scalars['String']>;
  labelFooterText: Maybe<Scalars['String']>;
  labelOpenBoxButton: Maybe<Scalars['String']>;
  langcode: Scalars['String'];
  lead: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  openBoxImage: Maybe<MediaImage>;
  path: Scalars['String'];
  template: Maybe<Scalars['String']>;
  title: Scalars['String'];
  translations: Array<BoxDonation>;
};

/** A single item that can be packed into a aid box donation option. */
export type BoxDonationOption = {
  __typename?: 'BoxDonationOption';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** The price this items adds to the total donation. */
  amount: Scalars['Int'];
  donationIcon: Maybe<MediaDonationIcon>;
};

export type CBoxContent = BlockHtmlList | BlockHtmlParagraph;

export type Canton = {
  __typename?: 'Canton';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<Canton>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  name: Scalars['String'];
  translations: Array<Canton>;
};

export type CollageImage = {
  __typename?: 'CollageImage';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  displaySource: Maybe<Scalars['Boolean']>;
  image: Maybe<MediaImage>;
};

export type CollageStoryContent = BlockHtmlList | BlockHtmlParagraph;

export enum CommunicationItemIcon {
  Accomodation = 'accomodation',
  Barn = 'barn',
  Child = 'child',
  Education = 'education',
  Farmer = 'farmer',
  Give = 'give',
  Globe = 'globe',
  Harvest = 'harvest',
  Like = 'like',
  Man = 'man',
  Meals = 'meals',
  Money = 'money',
  People = 'people',
  Sow = 'sow',
  Sustainability = 'sustainability',
  Water = 'water',
  Weather = 'weather',
  Woman = 'woman',
  WomanMan = 'womanMan'
}

export type ContentHubFacets = {
  __typename?: 'ContentHubFacets';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  country: Maybe<Array<TermCountry>>;
  format: Maybe<Array<TermFormat>>;
  /** Taxonomy terms for filtering */
  topics: Maybe<Array<TermTopic>>;
};

/**
 * Filter content hub listings by taxonomies.
 *
 * Multiple filters within one taxonomy vocabulary are OR connected
 * while the different vocabularies are AND connected
 *
 * Example:
 * topic:
 * [x] engagement schweiz
 * [ ] engagement weltweit
 * type:
 * [x] video
 * [x] image
 * [ ] press release
 *
 * ... will show videos and images from "engagement schweiz", but no press releases
 * and nothing from "engagement weltweit".
 */
export type ContentHubFilterInput = {
  countries: Array<Scalars['String']>;
  topics: Array<Scalars['String']>;
  types: Array<Scalars['String']>;
};

export type ContentHubInput = {
  filters: ContentHubFilterInput;
  langcode: Scalars['String'];
  pagination: PaginationInput;
  skipResults: InputMaybe<Scalars['Boolean']>;
};

export type ContentHubResult = {
  __typename?: 'ContentHubResult';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /**
   * Terms that are represented in the current result set.
   * Terms without results should not be displayed as filter options any more.
   */
  facets: Maybe<ContentHubFacets>;
  /** The result between the current limit and offset. */
  results: Array<ContentPage>;
  /** Total number of items in the result set. */
  total: Scalars['Int'];
};

/**
 * The generic "Page" content type, corresponding to the "Page" content type in
 * Drupal.
 *
 * Uses [Gutenberg](https://www.drupal.org/project/gutenberg) for block editing.
 */
export type ContentPage = WithTeaser & {
  __typename?: 'ContentPage';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<ContentPage>;
  /** Metadata settings */
  allowIndexing: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  body: Maybe<Scalars['String']>;
  /** A list of block elements, to be rendered in sequence. */
  content: Array<Block>;
  /**
   * A manual-set date range, used for listings and display a related date in
   * a structured way.
   */
  date: Maybe<DateTimeRange>;
  donationDescription: Maybe<Scalars['String']>;
  /**
   * Relates to a donation purpose for this page. This controls all instances of
   * donation related components on a page:
   * * the top right header donation button
   * * the hero donation widget
   * * the in-text embeddable donation widget
   *
   * If this is not set, the hero donation widget and the in-text donation widget
   * should simply not be displayed. The top right donation button should lead to
   * a configurable "donation overview" content page in that case.
   */
  donationPurpose: Maybe<DonationPage>;
  drupalId: Scalars['String'];
  format: Maybe<Array<TermFormat>>;
  header: ContentPageHeader;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaDescription: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  path: Scalars['String'];
  teaser: Maybe<Teaser>;
  title: Scalars['String'];
  translations: Array<ContentPage>;
};

/**
 * A mandatory header area, enforced by the editor on every content page.
 * Can contain a background image, a video that is displayed on demand, or both.
 */
export type ContentPageHeader = {
  __typename?: 'ContentPageHeader';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  ctaText: Maybe<Scalars['String']>;
  ctaUrl: Maybe<Scalars['String']>;
  displayMode: Maybe<Scalars['String']>;
  headline: Scalars['String'];
  lead: Maybe<Scalars['HTML']>;
  media: Maybe<HeaderMedia>;
  overviewNavigationHeading: Maybe<Scalars['String']>;
  overviewNavigationSubheading: Maybe<Scalars['String']>;
  /** Show the in-header donation widget, using the pages donationPurpose. */
  showDonationWidget: Maybe<Scalars['Boolean']>;
  /** Heading and subheading for the scrollover page hero area. */
  showOverviewNavAndTOC: Maybe<Scalars['Boolean']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};

export type CountryProgramme = {
  __typename?: 'CountryProgramme';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  label: Maybe<Scalars['String']>;
  langcode: Scalars['String'];
  path: Maybe<Scalars['String']>;
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  end: Maybe<Scalars['String']>;
  start: Scalars['String'];
};

/** A one time donation. */
export type Donation = DonationPurpose & WithTeaser & {
  __typename?: 'Donation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<Donation>;
  callToAction: Scalars['String'];
  campaignId: Scalars['String'];
  campaignSubId: Scalars['String'];
  disclaimer: Maybe<Scalars['String']>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  langcode: Scalars['String'];
  lead: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  /** The preset amounts to be presented in the donation widget for monthly donation. */
  monthlyAmounts: Maybe<DonationAmounts>;
  /** The preset amounts to be presented in the donation widget for one-time donations. */
  oneTimeAmounts: Maybe<DonationAmounts>;
  path: Scalars['String'];
  sextantContractTemplateUid: Scalars['String'];
  sextantProductUid: Scalars['String'];
  teaser: Maybe<Teaser>;
  template: Maybe<Scalars['String']>;
  thankYouPage: Maybe<ContentPage>;
  title: Scalars['String'];
  translations: Array<Donation>;
  /** The preset amounts to be presented in the donation widget for yearly donations. */
  yearlyAmounts: Maybe<DonationAmounts>;
};

export type DonationAmounts = {
  __typename?: 'DonationAmounts';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  amounts: Maybe<Array<Maybe<Scalars['String']>>>;
  default: Maybe<Scalars['Int']>;
};

/** Interval options the editor can choose to provide to users. */
export enum DonationInterval {
  Biannually = 'BIANNUALLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type DonationPage = BoxDonation | Donation | SponsorshipDonation | StockDonation;

/**
 * Common properties of donation pages.
 *
 * Each donation purpose is a node that produces a "donation page". Accessing any
 * other on-page ui elements leads ultimately to the donation purpose
 * node itself which displays the header image, the headline, lead text and
 * the embedded raisenow form.
 */
export type DonationPurpose = {
  /**
   * A more descriptive, engaging title that is displayed on the website alongside
   * the donation widget.
   */
  callToAction: Scalars['String'];
  /**
   * The active campaign id for this widget.
   * Manual input, mandatory. Used for tracking.
   * **NOTE:** Its unclear if this is also the raisenow campaign id that should
   * be passed into the widget configuration. If not, another field is required
   * for it.
   */
  campaignId: Scalars['String'];
  /** Sextant id for this donation purpose. */
  campaignSubId: Scalars['String'];
  /**
   * An optional image, added to this donation purpose.
   * Used for teaser displays and a hero image on the actual page.
   */
  image: Maybe<MediaImage>;
  /** A short additional lead text for this donation purpose. */
  lead: Maybe<Scalars['String']>;
  /**
   * A path. All donation purposes are Drupal nodes with friendly url paths that
   * can be accessed directly.
   */
  path: Scalars['String'];
  /**
   * TODO: What is this there for?
   * Manual input, mandatory.
   */
  sextantContractTemplateUid: Scalars['String'];
  /**
   * Sextant is the Caritas CRM. Every raisenow transaction has to relate to a product there.
   * Manual input, mandatory.
   */
  sextantProductUid: Scalars['String'];
  /** Internal title for this donation purpose. */
  title: Scalars['String'];
};

export type EmbedMedia = {
  __typename?: 'EmbedMedia';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  embedHtml: Maybe<Scalars['HTML']>;
  embedType: Maybe<BlockIframeEmbedType>;
  url: Maybe<Scalars['String']>;
};

export type FactSheetContent = BlockHtmlList | BlockHtmlParagraph | BlockHtmlTable | BlockQuote;

export type File = {
  __typename?: 'File';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  mimeType: Scalars['String'];
  size: Scalars['String'];
  url: Scalars['String'];
};

export type FileListMedia = MediaImage | MediaPdf | MediaZip;

/**
 * Translations of strings, sourced from Drupal interface translations.
 *
 * Fetched and processed by a custom query field in `apps/website/gatsby-node.ts`
 * and consumed by the `useTranslations` hook, which feeds it into an `react-intl`
 * provider.
 */
export type GatsbyStringTranslation = {
  __typename?: 'GatsbyStringTranslation';
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  context: Maybe<Scalars['String']>;
  dummy: Maybe<Scalars['String']>;
  source: Scalars['String'];
  translations: Maybe<Array<Maybe<GatsbyStringTranslationTranslation>>>;
};

export type GatsbyStringTranslationTranslation = {
  __typename?: 'GatsbyStringTranslationTranslation';
  id: Scalars['String'];
  langcode: Scalars['String'];
  source: Scalars['String'];
  translation: Scalars['String'];
};

export type HeaderMedia = MediaImage | MediaVideo;

export type HighlightedTeaserContent = ContentPage | Donation | LocationPage | ScrolloverPage | SponsorshipDonation | StockDonation;

/** The image file stored in Drupal. */
export type Image = {
  __typename?: 'Image';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  alt: Maybe<Scalars['String']>;
  cacheId: Scalars['String'];
  height: Scalars['Int'];
  mimeType: Scalars['String'];
  styles: Array<ImageStyle>;
  title: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

/** A scaled version of an image style. For example mobile, tablet or desktop. */
export type ImageDerivative = {
  __typename?: 'ImageDerivative';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type ImageStoryContent = BlockHtmlList | BlockHtmlParagraph;

/**
 * An automatically altered version of the image. Like portrait or landscape.
 * All cuts are centered around a focal point to avoid cutting off important parts
 * of the image, which can be set in the media library.
 */
export type ImageStyle = {
  __typename?: 'ImageStyle';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  derivatives: Array<ImageDerivative>;
  id: Scalars['String'];
};

export type ImpactElement = {
  __typename?: 'ImpactElement';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  impactMedia: Maybe<MediaImpactElement>;
};

export type InvalidBooking = {
  __typename?: 'InvalidBooking';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  booking: Booking;
  violations: Maybe<Array<Violation>>;
};

export type InvalidMissionItem = {
  __typename?: 'InvalidMissionItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  item: MissionItem;
  violations: Maybe<Array<Violation>>;
};

export type Language = {
  __typename?: 'Language';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<Language>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  name: Scalars['String'];
  translations: Array<Language>;
};

export type LinkWithText = {
  __typename?: 'LinkWithText';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
};

/** A single term that can optionally be linked. */
export type LinkableText = {
  __typename?: 'LinkableText';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  text: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type LocalizedLink = {
  __typename?: 'LocalizedLink';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  langcode: Scalars['String'];
  link: Scalars['String'];
};

/** The location type represents a farm location. */
export type LocationPage = WithTeaser & {
  __typename?: 'LocationPage';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<LocationPage>;
  additionalInformation: Maybe<Scalars['String']>;
  allowIndexing: Scalars['Boolean'];
  animals: Maybe<Array<Animal>>;
  bioLocation: Maybe<Scalars['Boolean']>;
  canton: Canton;
  carousel: Maybe<Array<Maybe<MediaImage>>>;
  carouselLayout: Maybe<Scalars['String']>;
  contactAddress: Maybe<Address>;
  contactEmail: Scalars['String'];
  contactMobilePhone: Maybe<Scalars['String']>;
  contactName: Scalars['String'];
  contactPhone: Maybe<Scalars['String']>;
  contactSurname: Scalars['String'];
  descriptionPartOne: Maybe<Scalars['String']>;
  descriptionPartTwo: Maybe<Scalars['String']>;
  drupalId: Scalars['String'];
  familyHeading: Maybe<Scalars['String']>;
  familyPicture: Maybe<MediaImage>;
  headerImage: Maybe<MediaImage>;
  id: Scalars['String'];
  introText: Scalars['String'];
  langcode: Scalars['String'];
  languages: Array<Language>;
  location: Maybe<Scalars['String']>;
  locationCoordinates: Coordinate;
  lodgings: Maybe<Scalars['String']>;
  mainActivity: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  path: Scalars['String'];
  projectNumber: Scalars['String'];
  sosLocation: Maybe<Scalars['Boolean']>;
  teaser: Maybe<Teaser>;
  title: Scalars['String'];
  translations: Array<LocationPage>;
  travelInformation: Maybe<Scalars['String']>;
  workTypes: Array<WorkType>;
};

export type LocationSearchInput = {
  filters: InputMaybe<LocationSearchInputFilter>;
  itemsPerPage: Scalars['Int'];
  langcode: Scalars['String'];
  page: Scalars['Int'];
};

export type LocationSearchInputFilter = {
  bioLocation: InputMaybe<Scalars['Boolean']>;
  canton: InputMaybe<Array<Scalars['String']>>;
  endDate: InputMaybe<Scalars['String']>;
  language: InputMaybe<Array<Scalars['String']>>;
  remainingSpots: InputMaybe<Scalars['Int']>;
  sosLocation: InputMaybe<Scalars['Boolean']>;
  startDate: InputMaybe<Scalars['String']>;
  workType: InputMaybe<Array<Scalars['String']>>;
};

export type LocationSearchResult = {
  __typename?: 'LocationSearchResult';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  content: Maybe<Array<LocationSearchResultItem>>;
  itemsPerPage: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  total: Maybe<Scalars['Int']>;
};

export type LocationSearchResultItem = LocationPage;

export enum MapSize {
  Default = 'DEFAULT',
  Large = 'LARGE'
}

/** Media type that contains contact information about people/organisations. */
export type MediaContact = {
  __typename?: 'MediaContact';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaContact>;
  additionalInfo: Maybe<Scalars['HTML']>;
  address: Maybe<Address>;
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  mail: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisation: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
};

/**
 * Media type that contains local images, which are scaled and cropped by Drupal
 * image processing.
 */
export type MediaDonationIcon = {
  __typename?: 'MediaDonationIcon';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaDonationIcon>;
  id: Scalars['String'];
  label: Scalars['String'];
  langcode: Scalars['String'];
  svgSource: Scalars['String'];
  translations: Array<MediaDonationIcon>;
};

/** A file that is hosted externally and offered as a download. */
export type MediaExternal = {
  __typename?: 'MediaExternal';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  description: Maybe<Scalars['HTML']>;
  previewImage: Maybe<Image>;
  title: Scalars['String'];
  url: Scalars['String'];
};

/**
 * Media type that contains local images, which are scaled and cropped by Drupal
 * image processing.
 */
export type MediaImage = {
  __typename?: 'MediaImage';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaImage>;
  caption: Maybe<Scalars['HTML']>;
  file: File;
  id: Scalars['String'];
  image: Maybe<Image>;
  /** The link to use when outputting thumbnail galleries */
  links: Maybe<Array<LocalizedLink>>;
  /** The media source the image originated from. For example the photographer. */
  source: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MediaImpactElement = {
  __typename?: 'MediaImpactElement';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaImpactElement>;
  actuality: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  icon: Maybe<CommunicationItemIcon>;
  id: Scalars['String'];
  metric: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
};

/** Media type that contains location data. */
export type MediaLocation = {
  __typename?: 'MediaLocation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaLocation>;
  coordinates: Maybe<Coordinate>;
  description: Maybe<Scalars['HTML']>;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type MediaPdf = {
  __typename?: 'MediaPdf';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaPdf>;
  description: Maybe<Scalars['HTML']>;
  file: File;
  id: Scalars['String'];
  previewImage: Maybe<Image>;
  title: Scalars['String'];
};

/** A content hub preview configuration. */
export type MediaTeaserListing = {
  __typename?: 'MediaTeaserListing';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaTeaserListing>;
  country: Array<TermCountry>;
  format: Array<TermFormat>;
  /** Hand picked items that will be displayed at the beginning of the list. */
  highlighted: Maybe<Array<Maybe<HighlightedTeaserContent>>>;
  id: Scalars['String'];
  moreLinkText: Maybe<Scalars['String']>;
  /**
   * List of taxonomy terms that will be used to filter the result set.
   * If this is populated, a defined amount of items is appended after the
   * "highlighted" items.
   *
   * Another item should be appended in the display that just links to the content
   * hub view, with the configured filters pre-selected.
   */
  topics: Array<TermTopic>;
  /**
   * Number of items in total.
   * If filters are set, and totalItems is bigger than the number of highlighted
   * items, the list is filled up with the dynamic result from the content hub.
   */
  totalItems: Scalars['Int'];
};

/** A locally hosted video, to be displayed in a HTML5 <video> tag. */
export type MediaVideo = {
  __typename?: 'MediaVideo';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaVideo>;
  description: Maybe<Scalars['HTML']>;
  file: File;
  id: Scalars['String'];
  previewImage: Maybe<Image>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type MediaZip = {
  __typename?: 'MediaZip';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MediaZip>;
  description: Maybe<Scalars['HTML']>;
  file: File;
  id: Scalars['String'];
  previewImage: Maybe<Image>;
  title: Scalars['String'];
};

export type MenuDonation = {
  __typename?: 'MenuDonation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuDonation>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuDonation>;
};

export type MenuFooter = {
  __typename?: 'MenuFooter';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuFooter>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuFooter>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
  parent: Scalars['String'];
  url: Scalars['String'];
};

export type MenuMain = {
  __typename?: 'MenuMain';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuMain>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuMain>;
};

export type MenuMeta = {
  __typename?: 'MenuMeta';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuMeta>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuMeta>;
};

export type MenuQuick = {
  __typename?: 'MenuQuick';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuQuick>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuQuick>;
};

export type MenuSocial = {
  __typename?: 'MenuSocial';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<MenuSocial>;
  items: Array<MenuItem>;
  langcode: Scalars['String'];
  translations: Array<MenuSocial>;
};

export type MissionItem = {
  __typename?: 'MissionItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  availableSpots: Scalars['Int'];
  endDate: Scalars['String'];
  id: Scalars['String'];
  location: LocationPage;
  /**
   * Flag if the current logged in user has already an active booking which
   * overlaps with the period of the mission item.
   */
  periodOverlapsForCurrentUser: Scalars['Boolean'];
  remainingSpots: Scalars['Int'];
  startDate: Scalars['String'];
  weekNumber: Scalars['Int'];
};

export type MissionItemBulkInput = {
  availableSpots: Scalars['Int'];
  endDate: Scalars['String'];
  locationId: Scalars['String'];
  period: InputMaybe<MissionItemPeriodInput>;
  startDate: Scalars['String'];
};

export type MissionItemCreateResponse = {
  __typename?: 'MissionItemCreateResponse';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  createdItems: Maybe<Array<MissionItem>>;
  invalidItems: Maybe<Array<InvalidMissionItem>>;
};

export type MissionItemInput = {
  availableSpots: Scalars['Int'];
  endDate: Scalars['String'];
  locationId: Scalars['String'];
  startDate: Scalars['String'];
};

export enum MissionItemPeriodInput {
  Daily = 'daily',
  Onetime = 'onetime',
  Weekly = 'weekly'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  createBooking: Maybe<BookingCreateResponse>;
  createBulkMissionItems: Maybe<MissionItemCreateResponse>;
  createMissionItem: Maybe<MissionItemCreateResponse>;
};


export type MutationCreateBookingArgs = {
  booking: InputMaybe<BookingInput>;
};


export type MutationCreateBulkMissionItemsArgs = {
  missions: InputMaybe<MissionItemBulkInput>;
};


export type MutationCreateMissionItemArgs = {
  mission: InputMaybe<MissionItemInput>;
};

/** Limit/Offset pagination for listing queries. */
export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PetitionResult = {
  __typename?: 'PetitionResult';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  confirmedSubmissions: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated Field no longer supported */
  _currentUser: _User;
  /** @deprecated Field no longer supported */
  _drupalBuildId: Scalars['Int'];
  /** @deprecated Field no longer supported */
  _drupalFeedInfo: Array<_Feed>;
  /** @deprecated Field no longer supported */
  _loadAnimal: Maybe<Animal>;
  /** @deprecated Field no longer supported */
  _loadAnimalRevision: Maybe<Animal>;
  /** @deprecated Field no longer supported */
  _loadBoxDonation: Maybe<BoxDonation>;
  /** @deprecated Field no longer supported */
  _loadBoxDonationRevision: Maybe<BoxDonation>;
  /** @deprecated Field no longer supported */
  _loadCanton: Maybe<Canton>;
  /** @deprecated Field no longer supported */
  _loadCantonRevision: Maybe<Canton>;
  /** @deprecated Field no longer supported */
  _loadContentPage: Maybe<ContentPage>;
  /** @deprecated Field no longer supported */
  _loadContentPageRevision: Maybe<ContentPage>;
  /** @deprecated Field no longer supported */
  _loadDonation: Maybe<Donation>;
  /** @deprecated Field no longer supported */
  _loadDonationRevision: Maybe<Donation>;
  /** @deprecated Field no longer supported */
  _loadGatsbyStringTranslation: Maybe<GatsbyStringTranslation>;
  /** @deprecated Field no longer supported */
  _loadLanguage: Maybe<Language>;
  /** @deprecated Field no longer supported */
  _loadLanguageRevision: Maybe<Language>;
  /** @deprecated Field no longer supported */
  _loadLocationPage: Maybe<LocationPage>;
  /** @deprecated Field no longer supported */
  _loadLocationPageRevision: Maybe<LocationPage>;
  /** @deprecated Field no longer supported */
  _loadMediaContact: Maybe<MediaContact>;
  /** @deprecated Field no longer supported */
  _loadMediaContactRevision: Maybe<MediaContact>;
  /** @deprecated Field no longer supported */
  _loadMediaDonationIcon: Maybe<MediaDonationIcon>;
  /** @deprecated Field no longer supported */
  _loadMediaDonationIconRevision: Maybe<MediaDonationIcon>;
  /** @deprecated Field no longer supported */
  _loadMediaImage: Maybe<MediaImage>;
  /** @deprecated Field no longer supported */
  _loadMediaImageRevision: Maybe<MediaImage>;
  /** @deprecated Field no longer supported */
  _loadMediaImpactElement: Maybe<MediaImpactElement>;
  /** @deprecated Field no longer supported */
  _loadMediaImpactElementRevision: Maybe<MediaImpactElement>;
  /** @deprecated Field no longer supported */
  _loadMediaLocation: Maybe<MediaLocation>;
  /** @deprecated Field no longer supported */
  _loadMediaLocationRevision: Maybe<MediaLocation>;
  /** @deprecated Field no longer supported */
  _loadMediaPdf: Maybe<MediaPdf>;
  /** @deprecated Field no longer supported */
  _loadMediaPdfRevision: Maybe<MediaPdf>;
  /** @deprecated Field no longer supported */
  _loadMediaTeaserListing: Maybe<MediaTeaserListing>;
  /** @deprecated Field no longer supported */
  _loadMediaTeaserListingRevision: Maybe<MediaTeaserListing>;
  /** @deprecated Field no longer supported */
  _loadMediaVideo: Maybe<MediaVideo>;
  /** @deprecated Field no longer supported */
  _loadMediaVideoRevision: Maybe<MediaVideo>;
  /** @deprecated Field no longer supported */
  _loadMediaZip: Maybe<MediaZip>;
  /** @deprecated Field no longer supported */
  _loadMediaZipRevision: Maybe<MediaZip>;
  /** @deprecated Field no longer supported */
  _loadMenuDonation: Maybe<MenuDonation>;
  /** @deprecated Field no longer supported */
  _loadMenuFooter: Maybe<MenuFooter>;
  /** @deprecated Field no longer supported */
  _loadMenuMain: Maybe<MenuMain>;
  /** @deprecated Field no longer supported */
  _loadMenuMeta: Maybe<MenuMeta>;
  /** @deprecated Field no longer supported */
  _loadMenuQuick: Maybe<MenuQuick>;
  /** @deprecated Field no longer supported */
  _loadMenuSocial: Maybe<MenuSocial>;
  /** @deprecated Field no longer supported */
  _loadScrolloverPage: Maybe<ScrolloverPage>;
  /** @deprecated Field no longer supported */
  _loadScrolloverPageRevision: Maybe<ScrolloverPage>;
  /** @deprecated Field no longer supported */
  _loadSettings: Maybe<Settings>;
  /** @deprecated Field no longer supported */
  _loadSettingsRevision: Maybe<Settings>;
  /** @deprecated Field no longer supported */
  _loadSponsorshipDonation: Maybe<SponsorshipDonation>;
  /** @deprecated Field no longer supported */
  _loadSponsorshipDonationRevision: Maybe<SponsorshipDonation>;
  /** @deprecated Field no longer supported */
  _loadStockDonation: Maybe<StockDonation>;
  /** @deprecated Field no longer supported */
  _loadStockDonationRevision: Maybe<StockDonation>;
  /** @deprecated Field no longer supported */
  _loadTermCountry: Maybe<TermCountry>;
  /** @deprecated Field no longer supported */
  _loadTermCountryRevision: Maybe<TermCountry>;
  /** @deprecated Field no longer supported */
  _loadTermFormat: Maybe<TermFormat>;
  /** @deprecated Field no longer supported */
  _loadTermFormatRevision: Maybe<TermFormat>;
  /** @deprecated Field no longer supported */
  _loadTermTopic: Maybe<TermTopic>;
  /** @deprecated Field no longer supported */
  _loadTermTopicRevision: Maybe<TermTopic>;
  /** @deprecated Field no longer supported */
  _loadWorkType: Maybe<WorkType>;
  /** @deprecated Field no longer supported */
  _loadWorkTypeRevision: Maybe<WorkType>;
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _queryAnimals: Array<Maybe<Animal>>;
  /** @deprecated Field no longer supported */
  _queryBoxDonations: Array<Maybe<BoxDonation>>;
  /** @deprecated Field no longer supported */
  _queryCantons: Array<Maybe<Canton>>;
  /** @deprecated Field no longer supported */
  _queryContentPages: Array<Maybe<ContentPage>>;
  /** @deprecated Field no longer supported */
  _queryDonations: Array<Maybe<Donation>>;
  /** @deprecated Field no longer supported */
  _queryGatsbyStringTranslations: Array<Maybe<GatsbyStringTranslation>>;
  /** @deprecated Field no longer supported */
  _queryLanguages: Array<Maybe<Language>>;
  /** @deprecated Field no longer supported */
  _queryLocationPages: Array<Maybe<LocationPage>>;
  /** @deprecated Field no longer supported */
  _queryMediaContacts: Array<Maybe<MediaContact>>;
  /** @deprecated Field no longer supported */
  _queryMediaDonationIcons: Array<Maybe<MediaDonationIcon>>;
  /** @deprecated Field no longer supported */
  _queryMediaImages: Array<Maybe<MediaImage>>;
  /** @deprecated Field no longer supported */
  _queryMediaImpactElements: Array<Maybe<MediaImpactElement>>;
  /** @deprecated Field no longer supported */
  _queryMediaLocations: Array<Maybe<MediaLocation>>;
  /** @deprecated Field no longer supported */
  _queryMediaPdfs: Array<Maybe<MediaPdf>>;
  /** @deprecated Field no longer supported */
  _queryMediaTeaserListings: Array<Maybe<MediaTeaserListing>>;
  /** @deprecated Field no longer supported */
  _queryMediaVideos: Array<Maybe<MediaVideo>>;
  /** @deprecated Field no longer supported */
  _queryMediaZips: Array<Maybe<MediaZip>>;
  /** @deprecated Field no longer supported */
  _queryMenuDonations: Array<Maybe<MenuDonation>>;
  /** @deprecated Field no longer supported */
  _queryMenuFooters: Array<Maybe<MenuFooter>>;
  /** @deprecated Field no longer supported */
  _queryMenuMains: Array<Maybe<MenuMain>>;
  /** @deprecated Field no longer supported */
  _queryMenuMetas: Array<Maybe<MenuMeta>>;
  /** @deprecated Field no longer supported */
  _queryMenuQuicks: Array<Maybe<MenuQuick>>;
  /** @deprecated Field no longer supported */
  _queryMenuSocials: Array<Maybe<MenuSocial>>;
  /** @deprecated Field no longer supported */
  _queryScrolloverPages: Array<Maybe<ScrolloverPage>>;
  /** @deprecated Field no longer supported */
  _querySettingss: Array<Maybe<Settings>>;
  /** @deprecated Field no longer supported */
  _querySponsorshipDonations: Array<Maybe<SponsorshipDonation>>;
  /** @deprecated Field no longer supported */
  _queryStockDonations: Array<Maybe<StockDonation>>;
  /** @deprecated Field no longer supported */
  _queryTermCountrys: Array<Maybe<TermCountry>>;
  /** @deprecated Field no longer supported */
  _queryTermFormats: Array<Maybe<TermFormat>>;
  /** @deprecated Field no longer supported */
  _queryTermTopics: Array<Maybe<TermTopic>>;
  /** @deprecated Field no longer supported */
  _queryWorkTypes: Array<Maybe<WorkType>>;
  /** Field to query the available mission items of a location (farm). */
  availableMissionItems: Maybe<Array<MissionItem>>;
  /**
   * Field to query pages for a content hub display.
   * Runs through SOLR to enable faceted search.
   * Sorted by a pages "start" date descending (newest come first).
   */
  contentHub: ContentHubResult;
  petitionResult: PetitionResult;
  searchLocations: LocationSearchResult;
};


export type Query_LoadAnimalArgs = {
  id: Scalars['String'];
};


export type Query_LoadAnimalRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadBoxDonationArgs = {
  id: Scalars['String'];
};


export type Query_LoadBoxDonationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadCantonArgs = {
  id: Scalars['String'];
};


export type Query_LoadCantonRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadContentPageArgs = {
  id: Scalars['String'];
};


export type Query_LoadContentPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadDonationArgs = {
  id: Scalars['String'];
};


export type Query_LoadDonationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadGatsbyStringTranslationArgs = {
  id: Scalars['String'];
};


export type Query_LoadLanguageArgs = {
  id: Scalars['String'];
};


export type Query_LoadLanguageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadLocationPageArgs = {
  id: Scalars['String'];
};


export type Query_LoadLocationPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaContactArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaContactRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaDonationIconArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaDonationIconRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaImageArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaImageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaImpactElementArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaImpactElementRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaLocationArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaLocationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaPdfArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaPdfRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaTeaserListingArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaTeaserListingRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaVideoArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaVideoRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMediaZipArgs = {
  id: Scalars['String'];
};


export type Query_LoadMediaZipRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadMenuDonationArgs = {
  id: Scalars['String'];
};


export type Query_LoadMenuFooterArgs = {
  id: Scalars['String'];
};


export type Query_LoadMenuMainArgs = {
  id: Scalars['String'];
};


export type Query_LoadMenuMetaArgs = {
  id: Scalars['String'];
};


export type Query_LoadMenuQuickArgs = {
  id: Scalars['String'];
};


export type Query_LoadMenuSocialArgs = {
  id: Scalars['String'];
};


export type Query_LoadScrolloverPageArgs = {
  id: Scalars['String'];
};


export type Query_LoadScrolloverPageRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadSettingsArgs = {
  id: Scalars['String'];
};


export type Query_LoadSettingsRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadSponsorshipDonationArgs = {
  id: Scalars['String'];
};


export type Query_LoadSponsorshipDonationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadStockDonationArgs = {
  id: Scalars['String'];
};


export type Query_LoadStockDonationRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadTermCountryArgs = {
  id: Scalars['String'];
};


export type Query_LoadTermCountryRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadTermFormatArgs = {
  id: Scalars['String'];
};


export type Query_LoadTermFormatRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadTermTopicArgs = {
  id: Scalars['String'];
};


export type Query_LoadTermTopicRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_LoadWorkTypeArgs = {
  id: Scalars['String'];
};


export type Query_LoadWorkTypeRevisionArgs = {
  id: Scalars['String'];
  revision: Scalars['String'];
};


export type Query_QueryAnimalsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryBoxDonationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryCantonsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryContentPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryDonationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryGatsbyStringTranslationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryLanguagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryLocationPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaContactsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaDonationIconsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaImagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaImpactElementsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaLocationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaPdfsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaTeaserListingsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaVideosArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMediaZipsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuDonationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuFootersArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuMainsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuMetasArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuQuicksArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryMenuSocialsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryScrolloverPagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QuerySettingssArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QuerySponsorshipDonationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryStockDonationsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryTermCountrysArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryTermFormatsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryTermTopicsArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type Query_QueryWorkTypesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};


export type QueryAvailableMissionItemsArgs = {
  location: Scalars['String'];
};


export type QueryContentHubArgs = {
  input: ContentHubInput;
};


export type QueryPetitionResultArgs = {
  webformId: Scalars['String'];
};


export type QuerySearchLocationsArgs = {
  input: LocationSearchInput;
};

/**
 * The "ScrolloverPage" type, corresponding to the "Scrollover" content type i
 * Drupal.
 *
 * Uses [Gutenberg](https://www.drupal.org/project/gutenberg) for block editing.
 */
export type ScrolloverPage = WithTeaser & {
  __typename?: 'ScrolloverPage';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<ScrolloverPage>;
  /** Metadata settings */
  allowIndexing: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  body: Maybe<Scalars['String']>;
  /** A list of block elements, to be rendered in sequence. */
  content: Array<ScrolloverSectionItem>;
  country: Maybe<Array<TermCountry>>;
  /**
   * A manual-set date range, used for listings and display a related date in
   * a structured way.
   */
  date: Maybe<DateTimeRange>;
  donationDescription: Maybe<Scalars['String']>;
  /**
   * Relates to a donation purpose for this page. This controls all instances of
   * donation related components on a page:
   * * the top right header donation button
   * * the hero donation widget
   * * the in-text embeddable donation widget
   *
   * If this is not set, the hero donation widget and the in-text donation widget
   * should simply not be displayed. The top right donation button should lead to
   * a configurable "donation overview" content page in that case.
   */
  donationPurpose: Maybe<DonationPage>;
  format: Maybe<TermFormat>;
  header: ContentPageHeader;
  id: Scalars['String'];
  langcode: Scalars['String'];
  metaDescription: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  path: Scalars['String'];
  teaser: Maybe<Teaser>;
  title: Scalars['String'];
  /** Taxonomy terms this page is related to. Valid vocabularies are. */
  topics: Maybe<Array<TermTopic>>;
  translations: Array<ScrolloverPage>;
};

export type ScrolloverSectionItem = {
  __typename?: 'ScrolloverSectionItem';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  cover: Maybe<ScrolloverSectionItemCover>;
  sectionContent: Maybe<Array<ScrolloverSectionItemContent>>;
};

export type ScrolloverSectionItemContent = {
  __typename?: 'ScrolloverSectionItemContent';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  content: Maybe<Array<Block>>;
  hasOverlay: Maybe<Scalars['Boolean']>;
};

export type ScrolloverSectionItemCover = {
  __typename?: 'ScrolloverSectionItemCover';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  background: Maybe<BackgroundStoryMedia>;
  content: Maybe<Array<Maybe<BackgroundStoryContent>>>;
  displaySource: Maybe<Scalars['Boolean']>;
  label: Maybe<Scalars['String']>;
  textPosition: Maybe<BlockBackgroundStoryTextPosition>;
  title: Maybe<Scalars['String']>;
};

export type Settings = {
  __typename?: 'Settings';
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  contentHubPagePath: Maybe<Scalars['String']>;
  contentHubPagePathCB: Maybe<Scalars['String']>;
  contentHubPagePathCC: Maybe<Scalars['String']>;
  contentHubPagePathYC: Maybe<Scalars['String']>;
  countryProgramme: Maybe<CountryProgramme>;
  headerCTAPathCB: Maybe<Scalars['String']>;
  headerCTAPathCC: Maybe<Scalars['String']>;
  headerCTAPathCS: Maybe<Scalars['String']>;
  headerCTAPathYC: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locationFamilyHeading: Maybe<Scalars['String']>;
  locationSearchPage: Maybe<ContentPage>;
  loginRegisterPage: Maybe<ContentPage>;
  myProfilePage: Maybe<ContentPage>;
  newsletterFormEmbedCodeCB: Maybe<Scalars['String']>;
  newsletterFormEmbedCodeCS: Maybe<Scalars['String']>;
  newsletterFormEmbedCodeYC: Maybe<Scalars['String']>;
  siteSearchPage: Maybe<ContentPage>;
  siteSearchPageCB: Maybe<ContentPage>;
  siteSearchPageCC: Maybe<ContentPage>;
  siteSearchPageYC: Maybe<ContentPage>;
};

/** A yearly donation that is presented as "its just 1 franc per day/week/month". */
export type SponsorshipDonation = DonationPurpose & WithTeaser & {
  __typename?: 'SponsorshipDonation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<SponsorshipDonation>;
  callToAction: Scalars['String'];
  campaignId: Scalars['String'];
  campaignSubId: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  langcode: Scalars['String'];
  lead: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  /** The preset amounts to be presented in the donation widget for monthly donation. */
  monthlyAmounts: Maybe<DonationAmounts>;
  path: Scalars['String'];
  sextantContractTemplateUid: Scalars['String'];
  sextantProductUid: Scalars['String'];
  teaser: Maybe<Teaser>;
  template: Maybe<Scalars['String']>;
  thankYouPage: Maybe<ContentPage>;
  title: Scalars['String'];
  translations: Array<SponsorshipDonation>;
  /** The preset amounts to be presented in the donation widget for yearly donations. */
  yearlyAmounts: Maybe<DonationAmounts>;
};

/** Every stock costs 500 francs. Users are able to change the amount. */
export type StockDonation = DonationPurpose & WithTeaser & {
  __typename?: 'StockDonation';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<StockDonation>;
  callToAction: Scalars['String'];
  campaignId: Scalars['String'];
  campaignSubId: Scalars['String'];
  drupalId: Scalars['String'];
  id: Scalars['String'];
  image: Maybe<MediaImage>;
  langcode: Scalars['String'];
  lead: Maybe<Scalars['String']>;
  modified: Scalars['String'];
  /**
   * The stock amounts to allow for pre-selection.
   * The actual donated amount is the selected stock amount, multiplied by 500.
   */
  oneTimeAmounts: Maybe<DonationAmounts>;
  path: Scalars['String'];
  sextantContractTemplateUid: Scalars['String'];
  sextantProductUid: Scalars['String'];
  teaser: Maybe<Teaser>;
  template: Maybe<Scalars['String']>;
  thankYouPage: Maybe<ContentPage>;
  title: Scalars['String'];
  translations: Array<StockDonation>;
};

/**
 * A teaser element, sourced from different elements within Drupal (News items,
 * pages, maybe a dedicated "teaser" type)
 */
export type Teaser = {
  __typename?: 'Teaser';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /**
   * The Location nodes can have the bio and sos flags, instead of the
   * headerDisplayMode.
   */
  bioLocation: Maybe<Scalars['Boolean']>;
  /** A date related to this content, it could be a manually-set published date, or an event date. */
  date: Maybe<DateTimeRange>;
  /**
   * A plain text string, since the description is also used for meta information
   * used in other channels. Also we are able to safely truncate too long
   * descriptions.
   */
  description: Maybe<Scalars['String']>;
  format: Array<TermFormat>;
  /** The header display mode, used to see if this is a disaster relief page. */
  headerDisplayMode: Maybe<Scalars['String']>;
  /** Reference to an image. */
  image: Maybe<MediaImage>;
  /**
   * The language of this content.
   * Required to mark it correctly if the embedding page is in a different language.
   */
  language: Scalars['String'];
  sosLocation: Maybe<Scalars['Boolean']>;
  status: Maybe<Scalars['Int']>;
  /** The title to be displayed. Every teaser has to have one. */
  title: Scalars['String'];
  /** The target url. */
  url: Scalars['String'];
};

/** Teaser list display modes. */
export enum TeaserListDisplay {
  /** A carousel. */
  Carousel = 'carousel',
  /** A 3 column grid display. */
  Grid = 'grid',
  /**
   * The first item is "promoted" on the left side, the other ones
   * are displayed on the right, scrolling vertically.
   */
  Promo = 'promo'
}

/** Common interface for taxonomy terms. */
export type Term = {
  /** The depth of the term, in the hierarchy. */
  depth: Scalars['Int'];
  /** A human readable label. Can be translated. */
  label: Scalars['String'];
  /** A machine id, used for referencing and filtering. Should be URL-friendly. */
  termId: Scalars['String'];
};

/** Countries */
export type TermCountry = Term & {
  __typename?: 'TermCountry';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<TermCountry>;
  depth: Scalars['Int'];
  id: Scalars['String'];
  label: Scalars['String'];
  termId: Scalars['String'];
  translations: Array<TermCountry>;
};

/** Media formats (Image, Video, News, Publication ...) */
export type TermFormat = Term & {
  __typename?: 'TermFormat';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<TermFormat>;
  depth: Scalars['Int'];
  id: Scalars['String'];
  label: Scalars['String'];
  termId: Scalars['String'];
  translations: Array<TermFormat>;
};

/** General topics (Switzerland, Poverty, Politics ...) */
export type TermTopic = Term & {
  __typename?: 'TermTopic';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<TermTopic>;
  depth: Scalars['Int'];
  id: Scalars['String'];
  label: Scalars['String'];
  termId: Scalars['String'];
  translations: Array<TermTopic>;
};

export type Violation = {
  __typename?: 'Violation';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  message: Scalars['String'];
};

/** Interface for elements that can be displayed in teaser lists. */
export type WithTeaser = {
  teaser: Maybe<Teaser>;
};

export type WorkType = {
  __typename?: 'WorkType';
  /** @deprecated Field no longer supported */
  _defaultTranslation: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  _drupalId: Scalars['String'];
  /** @deprecated Field no longer supported */
  _id: Scalars['String'];
  /** @deprecated Field no longer supported */
  _langcode: Scalars['String'];
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  /** @deprecated Field no longer supported */
  _translations: Array<WorkType>;
  drupalId: Scalars['String'];
  id: Scalars['String'];
  langcode: Scalars['String'];
  name: Scalars['String'];
  translations: Array<WorkType>;
};

export type _Feed = {
  __typename?: '_Feed';
  /** @deprecated Field no longer supported */
  _original_typename: Scalars['String'];
  changes: Array<Scalars['String']>;
  listFieldName: Scalars['String'];
  pathFieldName: Maybe<Scalars['String']>;
  singleFieldName: Scalars['String'];
  templateFieldName: Maybe<Scalars['String']>;
  translatable: Scalars['Boolean'];
  typeName: Scalars['String'];
};


export type _FeedChangesArgs = {
  currentBuild: InputMaybe<Scalars['Int']>;
  lastBuild: InputMaybe<Scalars['Int']>;
};

export type _User = {
  __typename?: '_User';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type ImageSourceFragment = { __typename?: 'ImageDerivative', url: string, width: number, height: number };

export type ImageFragmentFragment = { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> };

export type MediaImageFragmentFragment = { __typename?: 'MediaImage', image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined };

export type LocationSearchResultFragmentFragment = { __typename: 'LocationPage', id: string, title: string, path: string, introText: string, bioLocation: boolean | undefined, sosLocation: boolean | undefined, headerImage: { __typename?: 'MediaImage', image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, locationCoordinates: { __typename?: 'Coordinate', lon: number, lat: number }, workTypes: Array<{ __typename?: 'WorkType', drupalId: string }>, languages: Array<{ __typename?: 'Language', drupalId: string }>, canton: { __typename?: 'Canton', drupalId: string } };

export type LocationSearchResultsFragmentFragment = { __typename?: 'LocationSearchResult', total: number | undefined, content: Array<{ __typename: 'LocationPage', id: string, title: string, path: string, introText: string, bioLocation: boolean | undefined, sosLocation: boolean | undefined, headerImage: { __typename?: 'MediaImage', image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, locationCoordinates: { __typename?: 'Coordinate', lon: number, lat: number }, workTypes: Array<{ __typename?: 'WorkType', drupalId: string }>, languages: Array<{ __typename?: 'Language', drupalId: string }>, canton: { __typename?: 'Canton', drupalId: string } }> | undefined };

export type SearchLocationsQueryQueryVariables = Exact<{
  input: LocationSearchInput;
}>;


export type SearchLocationsQueryQuery = { __typename?: 'Query', searchLocations: { __typename?: 'LocationSearchResult', total: number | undefined, content: Array<{ __typename: 'LocationPage', id: string, title: string, path: string, introText: string, bioLocation: boolean | undefined, sosLocation: boolean | undefined, headerImage: { __typename?: 'MediaImage', image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, locationCoordinates: { __typename?: 'Coordinate', lon: number, lat: number }, workTypes: Array<{ __typename?: 'WorkType', drupalId: string }>, languages: Array<{ __typename?: 'Language', drupalId: string }>, canton: { __typename?: 'Canton', drupalId: string } }> | undefined } };

export type ImageFragment = { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> };

export type TeaserListItemFragment = { __typename?: 'Teaser', title: string, description: string | undefined, url: string, language: string, status: number | undefined, headerDisplayMode: string | undefined, date: { __typename?: 'DateTimeRange', start: string, end: string | undefined } | undefined, image: { __typename?: 'MediaImage', caption: any | undefined, image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, format: Array<{ __typename?: 'TermFormat', termId: string }> };

export type ContentHubResultFragmentFragment = { __typename?: 'ContentHubResult', total: number, facets: { __typename?: 'ContentHubFacets', topics: Array<{ __typename?: 'TermTopic', label: string, depth: number, id: string }> | undefined, country: Array<{ __typename?: 'TermCountry', label: string, depth: number, id: string }> | undefined, format: Array<{ __typename?: 'TermFormat', label: string, depth: number, id: string }> | undefined } | undefined, results: Array<{ __typename: 'ContentPage', id: string, teaser: { __typename?: 'Teaser', title: string, description: string | undefined, url: string, language: string, status: number | undefined, headerDisplayMode: string | undefined, date: { __typename?: 'DateTimeRange', start: string, end: string | undefined } | undefined, image: { __typename?: 'MediaImage', caption: any | undefined, image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, format: Array<{ __typename?: 'TermFormat', termId: string }> } | undefined }> };

export type ContentHubQueryVariables = Exact<{
  input: ContentHubInput;
}>;


export type ContentHubQuery = { __typename?: 'Query', contentHub: { __typename?: 'ContentHubResult', total: number, facets: { __typename?: 'ContentHubFacets', topics: Array<{ __typename?: 'TermTopic', label: string, depth: number, id: string }> | undefined, country: Array<{ __typename?: 'TermCountry', label: string, depth: number, id: string }> | undefined, format: Array<{ __typename?: 'TermFormat', label: string, depth: number, id: string }> | undefined } | undefined, results: Array<{ __typename: 'ContentPage', id: string, teaser: { __typename?: 'Teaser', title: string, description: string | undefined, url: string, language: string, status: number | undefined, headerDisplayMode: string | undefined, date: { __typename?: 'DateTimeRange', start: string, end: string | undefined } | undefined, image: { __typename?: 'MediaImage', caption: any | undefined, image: { __typename?: 'Image', cacheId: string, alt: string | undefined, styles: Array<{ __typename?: 'ImageStyle', id: string, derivatives: Array<{ __typename?: 'ImageDerivative', url: string, width: number, height: number }> }> } | undefined } | undefined, format: Array<{ __typename?: 'TermFormat', termId: string }> } | undefined }> } };

export type MissionItemResultFragmentFragment = { __typename?: 'MissionItem', id: string, startDate: string, endDate: string, weekNumber: number, periodOverlapsForCurrentUser: boolean, placesTotal: number, placesAvailable: number };

export type AvailableMissionItemsQueryVariables = Exact<{
  location: Scalars['String'];
}>;


export type AvailableMissionItemsQuery = { __typename?: 'Query', availableMissionItems: Array<{ __typename?: 'MissionItem', id: string, startDate: string, endDate: string, weekNumber: number, periodOverlapsForCurrentUser: boolean, placesTotal: number, placesAvailable: number }> | undefined };

export type UserFragmentFragment = { __typename?: '_User', name: string | undefined, uid: string | undefined };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: '_User', name: string | undefined, uid: string | undefined } };

export type PetitionResultFragmentFragment = { __typename?: 'PetitionResult', confirmedSubmissions: number };

export type PetitionResultQueryVariables = Exact<{
  webformId: Scalars['String'];
}>;


export type PetitionResultQuery = { __typename?: 'Query', petitionResult: { __typename?: 'PetitionResult', confirmedSubmissions: number } };

declare const OperationId: unique symbol;

type OperationId<
  TQueryResult extends any,
  TQueryVariables extends any,
> = string & {
  _opaque: typeof OperationId;
  ___query_result: TQueryResult;
  ___query_variables: TQueryVariables;
};

export type AnyOperationId = OperationId<any, any>;

export type OperationResult<TQueryID extends OperationId<any, any>> =
  TQueryID['___query_result'];

export type OperationVariables<TQueryID extends OperationId<any, any>> =
  TQueryID['___query_variables'];
export const SearchLocationsQueryQuery = "searchLocationsQuery:0d3fe3c7a6133eb02640317d5fbc0e2e18ee10ee23629344d671d7101e7d8ed0" as OperationId<SearchLocationsQueryQuery,SearchLocationsQueryQueryVariables>;
export const ContentHubQuery = "contentHub:7ead2eec4d14db40c011e0ef2989ff248f74c7105a57c4e923c42a4ee9fbdcb7" as OperationId<ContentHubQuery,ContentHubQueryVariables>;
export const AvailableMissionItemsQuery = "availableMissionItems:5ad16d929611fdc7d815e3882bb049bc1c58f07906bd4256cc15ac17162e06f9" as OperationId<AvailableMissionItemsQuery,AvailableMissionItemsQueryVariables>;
export const CurrentUserQuery = "currentUser:fb0ae55658e37e9c87959d84ef78990d36fcbbf17c35bb328175ddafab0c5864" as OperationId<CurrentUserQuery,CurrentUserQueryVariables | undefined>;
export const PetitionResultQuery = "petitionResult:8c38b8c6ad766288104a0c75f40de30a6623bbe716d6b5687d6ff8cbe066f909" as OperationId<PetitionResultQuery,PetitionResultQueryVariables>;